/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `'Noto Serif Display', serif;`,
  subHeading: `'Noto Serif Display', serif;`,
  body: `'Tinos', serif;`,
  body_lv2: `'Noto Serif Display', serif;`,
  fullName: `'Noto Serif Display', serif;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
    textAlign: 'center',
  },
  subHeading: {
    fontFamily: 'subHeading',
    fontSize: 'subHeading',
    fontWeight: 'subHeading',
    fontStyle: 'var(--fontItalic-subHeading)',
    textAlign: 'center',
    letterSpacing: '3px',
    textTransform: 'uppercase',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    textAlign: 'center',
  },
  body_lv2: {
    fontFamily: 'body_lv2',
    fontSize: 'body_lv2',
    fontWeight: 'body_lv2',
    fontStyle: 'var(--fontItalic-body_lv2)',
    textAlign: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  fullNameProps: {
    fontFamily: 'fullName',
    fontSize: 'fullName',
    fontWeight: 'fullName',
    fontStyle: 'var(--fontItalic-fullName)',
    letterSpacing: '3px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
};
