export const BG_PRIMARY = 'rgba(244,241,236,1)';
export const BG_SECONDARY = 'rgba(160,152,141,1)';
export const BG_ALTERNATIVE = 'rgba(110,97,80,1)';

export const TEXT_PRIMARY = 'rgba(12,12,12,1)';
export const TEXT_SECONDARY = 'rgba(255,255,255,1)';
export const TEXT_PRIMARY_LIGHT = 'rgba(255,255,255,1)';

export const BTN_NAVIGATION_COLOR = '#403d38';

// Opacity layer for left content (Desktop view)
export const BG_SECONDARY_TRANSPARENT = '';
